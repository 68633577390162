import { render, staticRenderFns } from "./ProfilAdreslerim.vue?vue&type=template&id=5bd179da&scoped=true&"
import script from "./ProfilAdreslerim.vue?vue&type=script&lang=js&"
export * from "./ProfilAdreslerim.vue?vue&type=script&lang=js&"
import style0 from "./ProfilAdreslerim.vue?vue&type=style&index=0&id=5bd179da&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5bd179da",
  null
  
)

export default component.exports